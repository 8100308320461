import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(utc);

import { defaultSettings } from '../defaults';

export enum TimerState {
  STOPPED,
  STARTED,
  PAUSED,
}

export default class Timer {
  settings = defaultSettings;
  initialCountdown: number;
  countdown: number;
  state: TimerState = TimerState.STOPPED;
  private interval: number | undefined;

  constructor(settings = defaultSettings) {
    this.settings = settings;
    this.countdown = this.initialCountdown = this.settings.countdown;
  }

  start(countdown?: number) {
    if (this.state !== TimerState.STARTED) {
      this.state = TimerState.STARTED;
      if (countdown) this.countdown = this.initialCountdown = countdown;
      this.interval = setInterval(this.onTick.bind(this), 1000);
    }
  }

  stop() {
    if (this.state !== TimerState.STOPPED) {
      this.state = TimerState.STOPPED;
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  pause() {
    this.state = TimerState.PAUSED;
    // TODO: is this needed???
  }

  reset(countdown?: number) {
    //this.stop();
    if (countdown) this.initialCountdown = countdown;
    this.countdown = this.initialCountdown;
  }

  toggle() {
    if (this.state === TimerState.STARTED) {
      this.stop();
    } else {
      this.start();
    }
  }

  onTick() {
    if (this.state === TimerState.STARTED) {
      this.countdown--;
      if (this.countdown <= this.settings.warningSeconds && this.countdown > this.settings.errorSeconds) {
        this.playSound('/sounds/tick.mp3');
        //this.playSound('http://soundbible.com/mp3/Beep-SoundBible.com-923660219.mp3');
      } else if (this.countdown === this.settings.errorSeconds) {
        this.playSound('/sounds/bell.mp3');
        //this.playSound('/sounds/buzzer.mp3');
        //this.playSound('http://soundbible.com/mp3/Fire_pager-jason-1283464858.mp3');
        if (this.settings.stopAtZero) this.stop();
      }
    }
  }

  toString(): string {
    const absCountdown = Math.abs(this.countdown);
    const sign = this.countdown < 0 ? '-' : '';
    return sign + dayjs.utc(dayjs.duration(absCountdown, 'seconds').asMilliseconds()).format(this.settings.format);
  }

  playSound(file: string) {
    if (this.settings.sound) {
      const audio = new Audio(file);
      audio.play();
    }
  }
}
